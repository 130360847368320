import React from "react";
import { Link } from "react-router-dom";

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top ${footerLight ? "footer-light" : "bg-dark"} ${
            footerGradient ? "bg-gradient" : ""
          }  text-white ptb-60`}
          style={style}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-6 mb-md-4 mb-lg-0">
                <div className="footer-single-col">
                  <div className="footer-single-col mb-4">
                    <img
                      src="assets/img/home/logo.png"
                      alt="logo"
                      className="img-fluid logo-white"
                      style={{ width: 200 }}
                    />
                    <img
                      src="assets/img/home/logo.png"
                      alt="logo"
                      className="img-fluid logo-color"
                      style={{ width: 200 }}
                    />
                  </div>
                  <p>CÔNG TY CỔ PHẦN CÔNG NGHỆ VÀ DỊCH VỤ ACHITEK</p>
                  <p>
                    SĐT -{" "}
                    <a className="text-white" href="tel:0964167037">
                      0964 167 037
                    </a>
                  </p>
                  <p>
                    Email -{" "}
                    <a
                      className="text-white text-decoration-underline"
                      href="mailto:contact@achitek.net"
                    >
                      contact@achitek.net
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-8 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                <div className="row">
                  <div className="col-md-6 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Trang chính</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/" className="text-decoration-none">
                            Trang chủ
                          </Link>
                        </li>
                        <li>
                          <Link to="/about-us" className="text-decoration-none">
                            Về chúng tôi
                          </Link>
                        </li>
                        <li>
                          <Link to="/blogs" className="text-decoration-none">
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Sản phẩm và giải pháp</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link
                            to="/product-and-solution"
                            className="text-decoration-none"
                          >
                            Phát triển ứng dụng phần mềm
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/product-and-solution"
                            className="text-decoration-none"
                          >
                            Phát triển sản phẩm AI
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/product-and-solution"
                            className="text-decoration-none"
                          >
                            Phát triển hệ thống dữ liệu lớn
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/product-and-solution"
                            className="text-decoration-none"
                          >
                            Phát triển sản phẩm blockchain và NFT
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
