import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

const Alert = ({ isShowing, hide, top, body, page, url = "#", state = null }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div
            className="modal d-block"
            style={{
              backgroundColor: "rgba(0, 0, 0, .5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title justify-content-center" id="modalConfirm">
                    {top}
                  </h5>
                </div>
                <div
                  className="modal-body text-center py-3"
                  style={{ fontSize: "16px" }}
                >
                  {body}
                </div>
                <div className="modal-footer justify-content-center py-0 mt-3">
                  <Link
                    className="btn btn-hover btn-primary px-4 py-2"
                    to={url}
                    onClick={hide}
                    state={state}
                  >
                    {page}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default Alert;
