import React, { useState } from "react";
import Alert from "../../layout/Modal/Alert";
import axios from "axios";
import useModal from "../../layout/Hooks/useModal";

const ContactFormTwo = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    question: "",
  });
  const [error, setError] = useState("");
  const { isShowing, toggle } = useModal();
  const [alert, setAlert] = useState({
    top: "",
    body: "",
    page: "",
    url: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let txtError = "";
    if (!data.phone || !data.email || !data.question) {
      txtError = "Vui lòng không để trống thông tin";
    } else {
      let params = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        email: data.email,
        question: data.question,
      };
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_URL_API_CONTACT}`,
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${process.env.REACT_APP_URL_API_CONTACT_KEY}`,
        },
        data: params,
      })
        .then((response) => {
          setAlert({
            top: "Hệ thống đã tiếp nhận thông tin của bạn",
            body: "Chúng tôi sẽ liên lạc cho các bạn",
            page: "OK",
            url: "/",
          });
          toggle();
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== "development") {
            console.log(err);
          }
          txtError = "Hệ thống đang bảo trì xin vui lòng thử lại sau";
        });
    }
    if (txtError) {
      setError(txtError);
    }
  };

  const setParams = (event) => {
    event.persist();
    setData((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <Alert
        isShowing={isShowing}
        hide={toggle}
        top={alert.top}
        body={alert.body}
        page={alert.page}
        url={alert.url}
      />
      <section
        className="contact-us-form pt-60 pb-120"
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-heading">
                <h2>Trao đổi với đội ngũ phát triển</h2>
                <p>
                  Để lại câu hỏi cho chúng tôi ở đây. Chúng tôi sẽ liên lạc với
                  bạn ngay sau khi nhận được lời nhắn.
                </p>
              </div>
              {error && (
                <div className="alert-danger text-center my-3">
                  <div>
                    <label className="form-label text-danger fw-bold">
                      {error}
                    </label>
                  </div>
                </div>
              )}
              <form
                action="#"
                className="register-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="firstName" className="mb-1">
                      Họ
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="Họ"
                        aria-label="Họ"
                        name="firstName"
                        onChange={setParams}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <label htmlFor="lastName" className="mb-1">
                      Tên
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Tên"
                        aria-label="Tên"
                        name="lastName"
                        onChange={setParams}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="phone" className="mb-1">
                      Số điện thoại <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Số điện thoại"
                        aria-label="Số điện thoại"
                        name="phone"
                        onChange={setParams}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="email" className="mb-1">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        aria-label="Email"
                        name="email"
                        onChange={setParams}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="yourMessage" className="mb-1">
                      Câu hỏi <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <textarea
                        className="form-control"
                        id="yourMessage"
                        placeholder="Chúng tôi có thể giúp gì cho bạn"
                        style={{ height: "120px" }}
                        name="question"
                        onChange={setParams}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-4">
                  Gửi
                </button>
              </form>
            </div>
            <div className="col-lg-5 col-md-10">
              <div className="contact-us-img">
                <img
                  src="assets/img/contact-us-img-2.svg"
                  alt="contact us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
