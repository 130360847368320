import React from "react";
import { Link } from "react-router-dom";
// import BlogPagination from "./BlogPagination";

const BlogGrid = () => {
  return (
    <>
      <section className="masonary-blog-section ptb-120">
        <div className="container">
          <div className="row">
            {/* <div className='col-lg-6 col-md-12'>
              <div className='single-article feature-article rounded-custom my-3'>
                <Link to='/blog-single' className='article-img'>
                  <img
                    src='assets/img/blog/blog-10.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Marketing
                    </Link>
                  </div>
                  <Link to='/blog-single'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Why product managers must be strategic about chasing new
                      trends
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/1.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Donna Martin</h6>
                        <span className='small fw-medium text-muted'>
                          April 24, 2022
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div className='col-lg-6 col-md-12'>
              <div className='single-article feature-article rounded-custom my-3'>
                <Link to='/blog-single' className='article-img'>
                  <img
                    src='assets/img/blog/blog-11.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-danger-soft'
                    >
                      Development
                    </Link>
                  </div>
                  <Link to='/blog-single'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Two tried-and-true frameworks for achieving product/market
                      fit
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/4.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Donna Martin</h6>
                        <span className='small fw-medium text-muted'>
                          April 24, 2022
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="/Xây-dựng-LLM-RAG-ChatBot-với-LangChain" className="article-img">
                  <img
                    src="assets/img/blog/LLM-RAG-Chatbot-650x400.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-primary-soft"
                    >
                      Data & AI
                    </Link>
                  </div>
                  <Link to="/Xây-dựng-LLM-RAG-ChatBot-với-LangChain">
                    <h2 className="h5 article-title limit-2-line-text">
                    Xây dựng LLM RAG ChatBot với LangChain
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                  Bạn có thể đã từng tương tác với các mô hình ngôn ngữ lớn (LLMs) như ChatGPT của OpenAI
                  và nhận thấy khả năng đáng chú ý của chúng trong việc trả lời câu hỏi, tóm tắt tài liệu, viết mã, và nhiều hơn nữa.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/6.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Tiến Nguyễn</h6>
                        <span className="small fw-medium text-muted">
                          2024-04-11
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="#" className="article-img">
                  <img
                    src="assets/img/blog/finance-chatbot.png"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-primary-soft"
                    >
                      Data & AI
                    </Link>
                  </div>
                  <Link to="#">
                    <h2 className="h5 article-title limit-2-line-text">
                    Xây dựng chatbot trong tài chính sử dụng LLM
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                  Chatbot là các ứng dụng chính để mô phỏng cuộc hội thoại của con người qua tương tác văn bản hoặc giọng nói.
                  Thách thức lớn nhất của chúng là việc trả lời các câu hỏi của người dùng một cách chính xác.
                  Các chatbot trước đây thường dựa vào các quy tắc, nơi mà tương tác tuân theo các bước được định sẵn.
                  Tuy nhiên, nhờ vào cuộc cách mạng của AI và cụ thể là các Mô hình Ngôn ngữ Lớn,
                  các chatbot thông minh có thể được phát triển rất nhanh chóng và có thể sử dụng cơ sở kiến thức được tạo ra để trả lời các câu hỏi phức tạp.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/1.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Tiến Nguyễn</h6>
                        <span className="small fw-medium text-muted">
                        2024-05-10
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="#" className="article-img">
                  <img
                    src="assets/img/blog/spark-1.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-danger-soft"
                    >
                      Development
                    </Link>
                  </div>
                  <Link to="#">
                    <h2 className="h5 article-title limit-2-line-text">
                    Spark: Xử lý 100GB file
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                  Xử lý tệp 100GB là điều dễ dàng với Spark, Chỉ khi bạn biết cách phân bổ bộ nhớ Spark một cách hiệu quả! Đọc để biết thêm chi tiết.


                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/3.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">John</h6>
                        <span className="small fw-medium text-muted">
                        2024-09-23
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="/blog-single" className="article-img">
                  <img
                    src="assets/img/blog/blog-4.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-primary-soft"
                    >
                      Marketing
                    </Link>
                  </div>
                  <Link to="/blog-single">
                    <h2 className="h5 article-title limit-2-line-text">
                      The role of product ops in successful distributed teams
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/4.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Raymond H. Martin</h6>
                        <span className="small fw-medium text-muted">
                          May 4, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="/blog-single" className="article-img">
                  <img
                    src="assets/img/blog/blog-5.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-warning-soft"
                    >
                      UI/UX
                    </Link>
                  </div>
                  <Link to="/blog-single">
                    <h2 className="h5 article-title limit-2-line-text">
                      The modern product manager’s tech stack
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/5.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Luthar Martin</h6>
                        <span className="small fw-medium text-muted">
                          Jan 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="/blog-single" className="article-img">
                  <img
                    src="assets/img/blog/blog-6.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-danger-soft"
                    >
                      Management
                    </Link>
                  </div>
                  <Link to="/blog-single">
                    <h2 className="h5 article-title limit-2-line-text">
                      30 product management thought leaders to follow
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/6.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Donna Martin</h6>
                        <span className="small fw-medium text-muted">
                          April 24, 2022
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="/blog-single" className="article-img">
                  <img
                    src="assets/img/blog/blog-7.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-danger-soft"
                    >
                      Design
                    </Link>
                  </div>
                  <Link to="/blog-single">
                    <h2 className="h5 article-title limit-2-line-text">
                      Do you really understand the concept of product value?
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/1.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Donna R. Book</h6>
                        <span className="small fw-medium text-muted">
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="/blog-single" className="article-img">
                  <img
                    src="assets/img/blog/blog-8.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-primary-soft"
                    >
                      Development
                    </Link>
                  </div>
                  <Link to="/blog-single">
                    <h2 className="h5 article-title limit-2-line-text">
                      A frank discussion about diversity, inclusion, and
                      allyship
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/3.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Donna R. Martin</h6>
                        <span className="small fw-medium text-muted">
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom my-3">
                <Link to="/blog-single" className="article-img">
                  <img
                    src="assets/img/blog/blog-9.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-warning-soft"
                    >
                      Design
                    </Link>
                  </div>
                  <Link to="/blog-single">
                    <h2 className="h5 article-title limit-2-line-text">
                      4 steps for measuring the impact of product discovery
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to="#">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/2.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Martin Luthar</h6>
                        <span className="small fw-medium text-muted">
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
          {/* <BlogPagination /> */}
        </div>
      </section>
    </>
  );
};

export default BlogGrid;
