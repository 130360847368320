import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/common/SectionTitle";

const LatestBlogHome = () => {
  return (
    <>
      <section className="home-blog-section ptb-120 bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                // subtitle="Blog"
                title="Blog"
                description="Cập nhật những thông tin mới nhất về công nghệ trong các lĩnh vực Data, AI, phát triển phần mềm."
                centerAlign
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom mb-4 mb-lg-0">
                <Link to="/Xây-dựng-LLM-RAG-ChatBot-với-LangChain" className="article-img">
                  <img
                    src="assets/img/blog/LLM-RAG-Chatbot-650x400.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-primary-soft"
                    >
                      Data & AI
                    </Link>
                  </div>
                  <Link to="/Xây-dựng-LLM-RAG-ChatBot-với-LangChain">
                    <h2 className="h5 article-title limit-2-line-text">
                      Xây dựng LLM RAG ChatBot với LangChain
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                  Bạn có thể đã từng tương tác với các mô hình ngôn ngữ lớn (LLMs) như ChatGPT của OpenAI
                  và nhận thấy khả năng đáng chú ý của chúng trong việc trả lời câu hỏi, tóm tắt tài liệu, viết mã, và nhiều hơn nữa.
                  </p>

                  <Link to="#/">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/6.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Tiến Nguyễn</h6>
                        <span className="small fw-medium text-muted">
                          2024-04-11
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom mb-4 mb-lg-0">
                <Link to="#" className="article-img">
                  <img
                    src="assets/img/blog/finance-chatbot.png"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <a
                      href="#/"
                      className="d-inline-block text-dark badge bg-primary-soft"
                    >
                      Data & AI
                    </a>
                  </div>
                  <Link to="#">
                    <h2 className="h5 article-title limit-2-line-text">
                      Xây dựng chatbot trong tài chính sử dụng LLM
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                  Chatbot là các ứng dụng chính để mô phỏng cuộc hội thoại của con người qua tương tác văn bản hoặc giọng nói.
                  Thách thức lớn nhất của chúng là việc trả lời các câu hỏi của người dùng một cách chính xác.
                  Các chatbot trước đây thường dựa vào các quy tắc, nơi mà tương tác tuân theo các bước được định sẵn.
                  Tuy nhiên, nhờ vào cuộc cách mạng của AI và cụ thể là các Mô hình Ngôn ngữ Lớn,
                  các chatbot thông minh có thể được phát triển rất nhanh chóng và có thể sử dụng cơ sở kiến thức được tạo ra để trả lời các câu hỏi phức tạp.
                  </p>

                  <Link to="#/">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/1.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Tiến Nguyễn</h6>
                        <span className="small fw-medium text-muted">
                          2024-05-10
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom mb-4 mb-lg-0 mb-md-0">
                <Link to="#" className="article-img">
                  <img
                    src="assets/img/blog/spark-1.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#/"
                      className="d-inline-block text-dark badge bg-danger-soft"
                    >
                      Big Data
                    </Link>
                  </div>
                  <Link to="#">
                    <h2 className="h5 article-title limit-2-line-text">
                      Spark: Xử lý 100GB file
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                  Xử lý tệp 100GB là điều dễ dàng với Spark, Chỉ khi bạn biết cách phân bổ bộ nhớ Spark một cách hiệu quả! Đọc để biết thêm chi tiết.


                  </p>

                  <Link to="#/">
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/testimonial/3.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">John</h6>
                        <span className="small fw-medium text-muted">
                          2024-09-23
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="text-center mt-5">
              <Link to="/blogs" className="btn btn-primary">
                Xem tất cả blogs
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestBlogHome;
