import React from 'react';
import ProfileCard1 from './ProfileCard1';

const BlogDetails1 = () => {
  return (
    <>
      <section className='blog-details ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='blog-details-wrap'>
                <p>
                Bạn có thể đã từng tương tác với các mô hình ngôn ngữ lớn (LLMs) như ChatGPT của OpenAI
                và nhận thấy khả năng đáng chú ý của chúng trong việc trả lời câu hỏi, tóm tắt tài liệu, viết mã, và nhiều hơn nữa.
                Mặc dù LLMs tự thân đã rất ấn tượng, nhưng chỉ cần một chút kiến thức lập trình, bạn có thể tận dụng các thư viện như LangChain để tạo ra các chatbot được hỗ trợ bởi LLM và có khả năng làm hầu như mọi thứ.
                </p>
                <p>
                Trong môi trường doanh nghiệp, một trong những cách phổ biến nhất để tạo ra chatbot được hỗ trợ bởi LLM là thông qua hệ thống tăng cường truy xuất (RAG). Khi bạn thiết kế một hệ thống RAG, bạn sử dụng mô hình truy xuất để lấy thông tin liên quan, thường là từ cơ sở dữ liệu hoặc bộ sưu tập, và cung cấp thông tin đã truy xuất này cho LLM để tạo ra các phản hồi phù hợp với ngữ cảnh.
                </p>
                <p>
                Trong hướng dẫn này, bạn sẽ đảm nhận vai trò của một kỹ sư AI làm việc cho một hệ thống bệnh viện lớn. Bạn sẽ xây dựng một chatbot RAG trong LangChain sử dụng Neo4j để truy xuất dữ liệu về bệnh nhân, trải nghiệm bệnh nhân, địa điểm bệnh viện, các chuyến thăm, các bên bảo hiểm, và các bác sĩ trong hệ thống bệnh viện của bạn.                </p>
                <div className='blog-details-info mt-5'>
                  <h3 className='h5'>Trong hướng dẫn này bạn sẽ nắm được</h3>
                  <ul className='content-list list-unstyled'>
                    <li>
                    Thiết kế chatbot dựa trên hiểu biết về yêu cầu kinh doanh và dữ liệu hệ thống bệnh viện
                    </li>
                    <li>
                    Làm việc với cơ sở dữ liệu đồ thị

                    </li>
                    <li>
                    Thiết lập một thực thể Neo4j AuraDB
                    </li>
                    <li>
                    Xây dựng chatbot RAG có khả năng truy xuất cả dữ liệu có cấu trúc và không có cấu trúc từ Neo4j
                    </li>
                    <li>
                    Triển khai chatbot của bạn với FastAPI và Streamlit
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div className='col-lg-4'>
              <ProfileCard1 />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails1;
