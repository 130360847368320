import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../themes/home/Home";
import NotFoundScreen from "../components/others/NotFoundScreen";
import AboutUs from "../themes/about-us/AboutUs";
import Product from "../themes/product/Product";
import ProductAI from "../themes/product-ai/ProductAI";
import ContactUs from "../themes/contact-us/ContactUs";
import ScrollToTop from "../components/common/ScrollToTop";
import HomeSoftApplication from "../themes/index5/HomeSoftApplication";
import HomeDataAnalysis from "../themes/index7/HomeDataAnalysis";
import HomeNine from "../themes/index9/HomeNine";
import SingleBlog from "../pages/blog/SingleBlog";
import Blog1 from "../pages/blog/Blog1";
import HomeStartup from "../themes/index6/HomeStartup";
import Blogs from "../pages/blog/Blogs";
// import HomeSassTwo from "../themes/index2/HomeSassTwo";
// import Services from "../pages/services/Services";
// import SingleService from "../pages/services/SingleService";
// import Login from "../pages/onboard/Login";
// import HomeDesktopApp from "../themes/index3/HomeDesktopApp";
// import PasswordReset from "../pages/onboard/PasswordReset";
// import Pricing from "../pages/Pricing";
// import HomeAppLanding from "../themes/index4/HomeAppLanding";
// import About from "../pages/About";
// import Contact from "../pages/Contact";
// import ComingSoon from "../pages/ComingSoon";
// import Integrations from "../pages/integration/Integrations";
// import IntegrationSingle from "../pages/integration/IntegrationSingle";
// import Career from "../pages/career/Career";
// import CareerSingle from "../pages/career/CareerSingle";
// import HelpCenter from "../pages/help-center/HelpCenter";
// import SingleHelpCenter from "../pages/help-center/SingleHelpCenter";
// import Signup from "../pages/onboard/Signup";
// import RequestDemo from "../pages/RequestDemo";
// import HomeAppTwo from "../themes/index8/HomeAppTwo";
// import HomeTen from "../themes/index10/HomeTen";
// import SingleProduct from "../pages/product/SingleProduct";
// import SinglePortfolio from "../pages/SinglePortfolio";
// import HomeEleven from "../themes/index11/HomeEleven";
// import HomeTwelve from "../themes/index12/HomeTwelve";
// import HomeThirteen from "../themes/index13/HomeThirteen";
// import HomeFourteen from "../themes/index14/HomeFourteen";

function MainPage() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route
          exact
          path="/plate-recognition"
          element={<HomeSoftApplication />}
        />
        <Route exact path="/bi-solution" element={<HomeDataAnalysis />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/face-recognition" element={<HomeNine />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog-single" element={<SingleBlog />} />
        <Route
          exact
          path="/Xây-dựng-LLM-RAG-ChatBot-với-LangChain"
          element={<Blog1 />}
        />
        <Route exact path="/software-development" element={<HomeStartup />} />
        <Route exact path="/product-and-solution" element={<Product />} />
        <Route exact path="/product-ai" element={<ProductAI />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route path="/404" element={<NotFoundScreen />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
        {/* <Route path="*" element={<NotFoundScreen />} /> */}
        {/* <Route exact path="/home-sass-two" element={<HomeSassTwo />} /> */}
        {/* <Route exact path="/home-desktop-app" element={<HomeDesktopApp />} /> */}
        {/* <Route exact path="/home-app-landing" element={<HomeAppLanding />} /> */}
        {/* <Route exact path="/home-startup" element={<HomeStartup />} /> */}
        {/* <Route exact path="/home-eleven" element={<HomeEleven />} /> */}
        {/* <Route exact path="/home-thirteen" element={<HomeThirteen />} /> */}
        {/* <Route exact path="/home-fourteen" element={<HomeFourteen />} /> */}
        {/* <Route exact path="/home-twelve" element={<HomeTwelve />} /> */}
        {/* <Route exact path="/home-app-two" element={<HomeAppTwo />} /> */}
        {/* <Route exact path="/home-ten" element={<HomeTen />} /> */}
        {/* <Route exact path="/single-product" element={<SingleProduct />} /> */}
        {/* <Route exact path="/portfolio-single" element={<SinglePortfolio />} /> */}
        {/* <Route exact path="/about-us" element={<About />} /> */}
        {/* <Route exact path="/services" element={<Services />} /> */}
        {/* <Route exact path="/single-service" element={<SingleService />} /> */}
        {/* <Route exact path="/contact-us" element={<Contact />} /> */}
        {/* <Route exact path="/pricing" element={<Pricing />} /> */}
        {/* <Route exact path="/career" element={<Career />} /> */}
        {/* <Route exact path="/career-single" element={<CareerSingle />} /> */}
        {/* <Route exact path="/help-center" element={<HelpCenter />} /> */}
        {/* <Route
          exact
          path="/help-center-single"
          element={<SingleHelpCenter />}
        /> */}
        {/* <Route exact path="/password-reset" element={<PasswordReset />} /> */}
        {/* <Route exact path="/login" element={<Login />} /> */}
        {/* <Route exact path="/signup" element={<Signup />} /> */}
        {/* <Route exact path="/request-for-demo" element={<RequestDemo />} /> */}
        {/* <Route exact path="/coming-soon" element={<ComingSoon />} /> */}
        {/* <Route exact path="/integrations" element={<Integrations />} /> */}
        {/* <Route
          exact
          path="/integration-single"
          element={<IntegrationSingle />}
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default MainPage;
