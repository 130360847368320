import React from 'react';
import BlogDetails1 from '../../components/blog/BlogDetails1';
import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Blog1 = () => {
  return (
    <Layout>
      <PageMeta title="Xây dựng LLM RAG ChatBot với LangChain" />
      <Navbar navDark />
      <PageHeader title="Xây dựng LLM RAG ChatBot với LangChain" />
      <BlogDetails1 />
      <FooterOne />
    </Layout>
  );
};

export default Blog1;
